import { useState, ChangeEvent, useEffect } from "react";
import { Button, Modal, Box, TextField, FormControl } from "@mui/material";
import { partOneType, seasons } from "../customConfig";
import MultiSelect from "./MultiSelect";
import { validateObjectNotEmpty } from "../utilities/validation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createPartOneQuestion,
  updatePartOneQuestion,
  deletePartOneQuestion,
} from "../utilities/request";

interface PartOneModalProps {
  open: boolean;
  selectedQuestion: PartOneQuestion | null;
  handleClose: () => void;
  topicID: string;
}

interface PartOneQuestion {
  question: string;
  season: string[];
  questionType: string[];
  typeName: "PartOne";
  topicID: string;
}

const initialPartOne: PartOneQuestion = {
  question: "",
  season: ["2025-1"],
  questionType: [],
  typeName: "PartOne",
  topicID: "",
};

const PartOneModal: React.FC<PartOneModalProps> = ({
  open,
  selectedQuestion,
  handleClose,
  topicID,
}) => {
  const [value, setValue] = useState<any>(
    selectedQuestion ? selectedQuestion : { ...initialPartOne, topicID }
  );
  useEffect(() => {
    if (selectedQuestion) {
      setValue(selectedQuestion);
    }
  }, [selectedQuestion]);

  const isEdit = !!selectedQuestion;

  const queryClient = useQueryClient();
  const createPartOneQuestionMutation = useMutation({
    mutationFn: createPartOneQuestion,
    onSuccess: (data) => {
      console.log("createPartOneQuestionMutation", data);
      queryClient.invalidateQueries({ queryKey: ["partOneTopics"] });
      onClose();
    },
  });

  const updatePartOneQuestionMutation = useMutation({
    mutationFn: updatePartOneQuestion,
    onSuccess: (data) => {
      console.log("updatePartOneQuestionMutation", data);
      queryClient.invalidateQueries({ queryKey: ["partOneTopics"] });
      onClose();
    },
  });

  const deletePartOneQuestionMutation = useMutation({
    mutationFn: deletePartOneQuestion,
    onSuccess: (data) => {
      console.log("deletePartOneQuestionMutation", data);
      queryClient.invalidateQueries({ queryKey: ["partOneTopics"] });
      onClose();
    },
  });

  const onClose = () => {
    setValue(initialPartOne);
    handleClose();
  };

  const onDelete = () => {
    if (value.id) {
      deletePartOneQuestionMutation.mutate(value.id);
    }
  };

  const handleQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, question: event.target.value });
  };

  const handleTypeChange = (questionType: string | string[]) => {
    if (Array.isArray(questionType)) {
      setValue({ ...value, questionType });
    }
  };

  const handleSeasonChange = (season: string[] | string) => {
    if (Array.isArray(season)) {
      setValue({ ...value, season });
    }
  };
  //handleSumbit
  const handleSubmit = () => {
    if (!value.topicID) {
      value.topicID = topicID;
    }
    if (validateObjectNotEmpty(value)) {
      if (isEdit) {
        updatePartOneQuestionMutation.mutate(value);
      } else {
        createPartOneQuestionMutation.mutate(value);
      }
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <TextField
            label="Question"
            variant="outlined"
            value={value.question}
            onChange={handleQuestionChange}
            fullWidth
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <MultiSelect
              value={value.questionType}
              setValue={handleTypeChange}
              options={[...partOneType, ""]}
              label="Question Type"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <MultiSelect
              value={value.season}
              setValue={handleSeasonChange}
              options={seasons}
              label="Season"
            />
          </FormControl>
          <Button onClick={handleSubmit}>
            {!isEdit ? "Create" : "Update"}
          </Button>
          <Button onClick={onClose}>Close</Button>
          {value.id && <Button onClick={onDelete}>Delete</Button>}
        </Box>
      </Modal>
    </div>
  );
};

export default PartOneModal;
