import { useState, ChangeEvent, useEffect } from "react";
import { Button, Modal, Box, TextField, FormControl } from "@mui/material";
import { partThreeType, seasons } from "../customConfig";
import MultiSelect from "./MultiSelect";
import { validateObjectNotEmpty } from "../utilities/validation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createPartThree,
  updatePartThree,
  deletePartThree,
} from "../utilities/request";
import SingleSelect from "./SingleSelect";

interface PartThreeModalProps {
  open: boolean;
  selectedQuestion: PartThree | null;
  handleClose: () => void;
  partTwoID: string;
}

interface PartThree {
  question: string;
  season: string[];
  type: string[];
  typeName: "PartThree";
  partTwoID: string;
}

const initialPartThree: PartThree = {
  question: "",
  season: ["2025-1"],
  type: [],
  typeName: "PartThree",
  partTwoID: "",
};

const PartThreeModal: React.FC<PartThreeModalProps> = ({
  open,
  selectedQuestion,
  handleClose,
  partTwoID,
}) => {
  const [value, setValue] = useState<any>(
    selectedQuestion ? selectedQuestion : { ...initialPartThree, partTwoID }
  );
  useEffect(() => {
    if (selectedQuestion) {
      setValue(selectedQuestion);
    }
  }, [selectedQuestion]);

  const isEdit = !!selectedQuestion;

  const queryClient = useQueryClient();
  //to change

  const createPartThreeMutation = useMutation({
    mutationFn: createPartThree,
    onSuccess: (data) => {
      console.log("createPartThreeMutation", data);
      queryClient.invalidateQueries({ queryKey: ["partTwos"] });
      onClose();
    },
  });

  const updatePartThreeMutation = useMutation({
    mutationFn: updatePartThree,
    onSuccess: (data) => {
      console.log("updatePartThreeMutation", data);
      queryClient.invalidateQueries({ queryKey: ["partTwos"] });
      onClose();
    },
  });

  const deletePartThreeMutation = useMutation({
    mutationFn: deletePartThree,
    onSuccess: (data) => {
      console.log("deletePartThreeMutation", data);
      queryClient.invalidateQueries({ queryKey: ["partTwos"] });
      onClose();
    },
  });

  const onClose = () => {
    setValue(initialPartThree);
    handleClose();
  };
  const onDelete = () => {
    if (value.id) {
      deletePartThreeMutation.mutate(value.id);
    }
  };
  const handleQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, question: event.target.value });
  };

  const handleTypeChange = (type: string) => {
    setValue({ ...value, type });
  };

  const handleSeasonChange = (season: string[] | string) => {
    if (Array.isArray(season)) {
      setValue({ ...value, season });
    }
  };

  const handleSubmit = () => {
    if (!value.partTwoID) {
      value.partTwoID = partTwoID;
    }
    if (validateObjectNotEmpty(value)) {
      if (isEdit) {
        updatePartThreeMutation.mutate(value);
      } else {
        createPartThreeMutation.mutate(value);
      }
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <TextField
            label="Question"
            variant="outlined"
            value={value.question}
            onChange={handleQuestionChange}
            fullWidth
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <SingleSelect
              value={value.type}
              setValue={handleTypeChange}
              options={[...partThreeType, ""]}
              label="Type"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <MultiSelect
              value={value.season}
              setValue={handleSeasonChange}
              options={seasons}
              label="Season"
            />
          </FormControl>
          <Button onClick={handleSubmit}>
            {!isEdit ? "Create" : "Update"}
          </Button>
          <Button onClick={onClose}>Close</Button>
          {value.id && <Button onClick={onDelete}>Delete</Button>}
        </Box>
      </Modal>
    </div>
  );
};

export default PartThreeModal;
